import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './SimpleHeader.css';

class SimpleHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.node,
  };

  render() {
    const { title, subtitle } = this.props;

    return (
      <header className={s.header}>
        <h1 className={cx(s.title, title.length < 20 || s.longtitle)}>{title}</h1>
        { subtitle && <p className={s.subtitle}>{subtitle}</p>}
      </header>
    );
  }
}

export default withStyles(s)(SimpleHeader);
