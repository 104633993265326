import React from 'react';
import Layout from '../../components/Layout';
import NotFound from './NotFound';
import graphqlclient from '../../core/graphqlclient';

const title = 'Pagina niet gevonden';

async function action({ data, req }) {
  const { isloaded } = data;
  let { user } = data;

  if (!isloaded) {
    const newdata = await graphqlclient(`
      query NotFoundPage {
        me { displayname }
      }
    `, null, req);

    user = newdata.me;
  }

  return {
    title,
    chunks: ['not-found'],
    component: <Layout user={user}><NotFound title={title} /></Layout>,
    status: 404,
    data: { user, isloaded: true },
  };
}

export default action;
